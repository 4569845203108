import React from "react";
//Need this to switch pages
//V6 React Router Dom Updates
import { v4 as uuid } from "uuid";
import ReactDOM from "react-dom";
import swal from "sweetalert";
import ReCAPTCHA from "react-recaptcha";
// AWS Amplify Stuff
import Amplify, { API, graphqlOperation } from "aws-amplify";
import { createForm } from "../graphql/mutations.js";
import { getForm } from "../graphql/queries.js";
import awsconfig from "../aws-exports.js";
Amplify.configure(awsconfig);
// create a variable to store the component instance
let recaptchaInstance;

class ScreeningQuestionForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputs: {},
      token: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReCAPTCHA = this.handleReCAPTCHA.bind(this);
    this.recaptchaLoaded = this.recaptchaLoaded.bind(this);
    this.resetRecaptcha = this.resetRecaptcha.bind(this);
  }

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    let obj = this.state.inputs;
    obj[name] = value;
    this.setState({ inputs: obj });
  };

  handleSubmit = (event) => {
    //testing
    // for(const prop in this.state.inputs){
    //     console.log(`${prop}: ${this.state.inputs[prop]}`);
    // }
    event.preventDefault();
  };

  handleReCAPTCHA = (value) => {
    // console.log("ReCaptcha value: " + value);
    if (value) {
      this.setState({ token: value });
    }
  };

  recaptchaLoaded = () => {
    console.log("CAPTCHA SUCCESSFULLY LOADED!");
  };

  // reset function
  // this function is needed to prevent a bot from spamming empty form submissions since recaptcha is still verified and token value is still permissable even if recaptcha verification is expired (because expired token value is still stored in this.state.token which needs to be updated to empty after submission).
  resetRecaptcha = () => {
    recaptchaInstance.reset();
    this.setState({ inputs: {} }); //clear up inputs object here too
    this.setState({ token: "" }); //clear out token variable
  };

  submitScreeningQuestionForm = async () => {
    try {
      if (this.state.token === "") {
        console.log("verifyCaptcha failed.");
        swal(
          "Please verify you are not a robot! If verification box is not present, screenshot your results and text it to (415)-996-5669."
        );
        return;
      }
      if (document.querySelector("#privacy").checked === false) {
        console.log("privacy checkbox was not checked. Please check the box.");
        return;
      }

      const data = {
        id: uuid(),
        token: this.state.token,
        firstname: this.state.inputs.firstname,
        lastname: this.state.inputs.lastname,
        phone: this.state.inputs.phone,
        email: this.state.inputs.email,
        recommender: document.querySelector(".referral").value,
        foundus: document.querySelector("#found-us").value,
        subject: this.state.inputs.subject,
        message: this.state.inputs.message,
        dogexperience: document.querySelector("#dog-experience").value,
        householdpets: document.querySelector("#current-pets").value,
        peopleyouknow: document.querySelector("#people-you-know").value,
        familymember: document.querySelector("#TF_Question1").value,
        financiallyprepared: document.querySelector("#TF_Question2").value,
        patience: document.querySelector("#TF_Question3").value,
        familynetwork: document.querySelector("#TF_Question4").value,
        physicalactivities: document.querySelector("#TF_Question5").value,
        mentalpreparedness: document.querySelector("#TF_Question6").value,
        caretaker: document.querySelector("#TF_Question7").value,
        allergic: document.querySelector("#TF_Question8").value,
        allergictest: document.querySelector("#TF_Question9").value,
        dogsallowed: document.querySelector("#TF_Question10").value,
        fencedyard: document.querySelector("#TF_Question11").value,
        housetraining: document.querySelector("#TF_Question12").value,
        trainingclasses: document.querySelector("#TF_Question13").value,
        breeding: document.querySelector("#TF_Question14").value,
        familypet: document.querySelector("#TF_Question15").value,
        specialtraining: document.querySelector("#TF_Question16").value,
        customerready: document.querySelector("#TF_Question17").value,
        puppystress: document.querySelector("#TF_Question18").value,
        concernscommunication: document.querySelector("#TF_Question19").value,
        petinsurance: document.querySelector("#TF_Question20").value,
        medicalemergency: document.querySelector("#TF_Question21").value,
        shedding: document.querySelector("#TF_Question22").value,
        buyerprotections: document.querySelector("#TF_Question23").value,
        norefundpolicy: document.querySelector("#TF_Question24").value,
        petsmeetnewpuppy: document.querySelector("#TF_Question25").value,
        ownership: document.querySelector("#TF_Question26").value,
        returnpolicy: document.querySelector("#TF_Question27").value,
        fourfigures: document.querySelector("#TF_Question28").value,
        nodeposits: document.querySelector("#TF_Question29").value,
        lineofwork: document.querySelector("#line-of-work").value,
        worklifestyle: document.querySelector("#family-worklifestyle").value,
        primarycaretaker: document.querySelector("#primary-caretaker").value,
        placeofresidence: document.querySelector("#place-of-residence").value,
        puppyhome: document.querySelector("#puppy-home").value,
        alonetime: document.querySelector("#alone-time").value,
        familychildren: document.querySelector("#family-children").value,
        teentroubles: document.querySelector("#teen-troubles").value,
        puppygender: document.querySelector("#puppy-gender").value,
        activitylevel: document.querySelector("#activity-level").value,
        championshipshows: document.querySelector("#championship-shows").value,
        oldpets: document.querySelector("#old-pets").value,
        puppysearch: document.querySelector("#puppy-search").value,
        puppyreturn: document.querySelector("#puppy-return").value,
        puppygiveaway: document.querySelector("#puppy-giveaway").value,
        vetinfo: document.querySelector("#vet-info").value,
        recap: document.querySelector("#TF_Last_Question").value,
        waitlist: document.querySelector("#Wait_List_Question").value,
        meeting: document.querySelector("#available-meeting-time").value,
        privacy: document.querySelector("#privacy").value,
      };
      // console.log("Before: ", data)
      await API.graphql(graphqlOperation(createForm, { input: data }));
      // console.log("After ")
      try {
        const formReceived = await API.graphql(
          graphqlOperation(getForm, { id: data.id })
        );
        const formResult = formReceived.data.getForm;
        console.log("verifyCaptcha success!!!");
        swal(
          "Inquiry Successfully Sent",
          "Thank you " +
            formResult.firstname +
            " " +
            formResult.lastname +
            " for your submission. \n\n We will contact your phone number (" +
            formResult.phone +
            ") at our earliest convenience to provide further details about our available puppies and our process. ",
          "success",
          { button: "close" }
        );
        document.querySelector(".contact-form").reset();
        this.resetRecaptcha(); //reset recaptcha
        return;
      } catch (error) {
        console.log("Inner Error: ", error);
        swal(
          "Something went wrong!",
          "Please complete and submit the screening questions again. \n\n (Friendly tip: Screenshot or save a pdf of your responses before submitting again and text or email us the screenshots as a backup. )",
          "error",
          { button: "close" }
        );
      }
    } catch (error) {
      console.log("Outer Error: ", error);
      console.log(error.data);
      console.log(error.errors);
    }
  };

  render() {
    return (
      <section className="contact-form-container">
        <p className="contact-title">CONTACT FORM & SCREENING QUESTIONS</p>
        <p className="contact-instructions">
          Corgi inquiry or questions. Please fill in the required info. Some are
          optional. Screening Questions also need to be completed (~15 minutes).
          Please glance through all the questions before beginning it. Again
          some questions are optional and could be skipped. Required questions
          contain the "*" or other labels to tell you it's required. Thank you!
          <br />
          <br />
          <span className="corgi_color">- Bay Area Corgis</span>
        </p>

        <form
          className="contact-form"
          id="contact-form"
          onSubmit={this.handleSubmit}
          method="POST"
        >
          <label htmlFor="firstname">
            First Name<span className="corgi_color">*</span>
          </label>
          <input
            type="text"
            className="firstname"
            id="firstname"
            name="firstname"
            placeholder="first name (required)"
            value={this.state.inputs.firstname}
            onChange={this.handleChange}
            required
          />

          <label htmlFor="lastname">
            Last Name<span className="corgi_color">*</span>
          </label>
          <input
            type="text"
            className="lastname"
            id="lastname"
            name="lastname"
            placeholder="last name (required)"
            value={this.state.inputs.lastname}
            onChange={this.handleChange}
            required
          />

          <label htmlFor="phone">
            Phone Number<span className="corgi_color">*</span> (Format:
            1234567890)
          </label>
          <input
            type="tel"
            className="phone"
            id="phone"
            name="phone"
            pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
            placeholder="phone number (required)"
            value={this.state.inputs.phone}
            onChange={this.handleChange}
            required
          />

          <label htmlFor="email">
            email<span className="corgi_color">*</span>
          </label>
          <input
            type="email"
            className="email"
            id="email"
            name="email"
            placeholder="email (required)"
            value={this.state.inputs.email}
            onChange={this.handleChange}
            required
          />

          <label htmlFor="referral">
            Did someone recommend you to us? If so, enter their name.
          </label>
          <input
            type="text"
            className="referral"
            id="referral"
            name="referral"
            placeholder="name of recommender (optional)"
            value={this.state.inputs.referral}
            onChange={this.handleChange}
          />

          <label htmlFor="found-us">
            How did you find out about us?
            <span className="corgi_color">*</span>
          </label>
          <select
            id="found-us"
            name="foundus"
            defaultValue="Empty"
            value={this.state.inputs.foundus}
            onChange={this.handleChange}
            required
          >
            <option value="Empty"></option>
            <option value="Google Ads">Google Ad</option>
            <option value="Google Business Profile">
              Google Business Profile
            </option>
            <option value="Google Search">Google Search</option>
            <option value="Word of Mouth">Word of Mouth</option>
            <option value="Yelp">Yelp</option>
            <option value="Facebook">Facebook</option>
            <option value="Instagram">Instagram</option>
            <option value="Twitter">Twitter</option>
            <option value="Twitter">YouTube</option>
            <option value="Other Social Media">Other Social Media?</option>
            <option value="Other Method">Other</option>
          </select>

          <label htmlFor="subject">
            Subject<span className="corgi_color">*</span>
          </label>
          <input
            type="text"
            className="subject"
            id="subject"
            name="subject"
            placeholder="subject (required)"
            value={this.state.inputs.subject}
            onChange={this.handleChange}
            required
          />

          <label htmlFor="message">
            Your Message<span className="corgi_color">*</span>
          </label>
          <textarea
            id="message"
            className="message"
            name="message"
            placeholder="message (required)"
            value={this.state.inputs.message}
            onChange={this.handleChange}
            required
          ></textarea>

          <h2 className="screening-questions">
            SCREENING QUESTIONS (REQUIRED)
          </h2>
          <label htmlFor="dog-experience">
            How much experience do you have in raising a dog?
            <span className="corgi_color">*</span>
          </label>
          <select
            id="dog-experience"
            name="dog-experience"
            value={this.state.inputs.dogexperience}
            onChange={this.handleChange}
            required
          >
            <option value="Empty"></option>
            <option value="No Experience">First-time Dog Owner</option>
            <option value="I am a Cat Person">I am a Cat Person</option>
            <option value="Watched after a Dog at least once">
              Watched After A Dog At Least Once
            </option>
            <option value="Grew up with Dogs in the family">
              Grew up with Dogs in the family
            </option>
            <option value="Competed in Dog Shows">Competed in Dog Shows</option>
            <option value="Professionally Trained Dogs">
              Professionally Trained Dogs
            </option>
            <option value="Breeded Dogs Before">Breeded Dogs Before</option>
            <option value="Currently a Vet or Vet Tech">
              Currently a Vet or Vet Tech
            </option>
            <option value="Other">Other</option>
          </select>

          <label htmlFor="current-pets">
            Do you currently have any pets in your household? If so, what kind
            of pets? <span className="corgi_color">*</span>
          </label>
          <input
            type="text"
            className="current-pets"
            id="current-pets"
            name="current-pets"
            placeholder="Mention how many and what kind of pets you currently have."
            value={this.state.inputs.householdpets}
            onChange={this.handleChange}
            required
          />

          <label htmlFor="people-you-know">
            How many people do you know have Corgis in their family?{" "}
            <span className="corgi_color">*</span>
          </label>
          <select
            id="people-you-know"
            name="people-you-know"
            value={this.state.inputs.peopleyouknow}
            onChange={this.handleChange}
            required
          >
            <option value="Empty"></option>
            <option value="0">0</option>
            <option value="1">1</option>
            <option value="2 or more">2 or more</option>
          </select>

          <label htmlFor="TF_Question1">
            Through all the happy and difficult times you may face as a family,
            will you treat our puppy like family?{" "}
            <span className="corgi_color">*</span>
          </label>
          <select
            id="TF_Question1"
            name="TF_Question1"
            value={this.state.inputs.familymember}
            onChange={this.handleChange}
            required
          >
            <option value="Empty"></option>
            <option value="PASS">TRUE</option>
            <option value="FAIL">FALSE</option>
          </select>

          <label htmlFor="TF_Question2">
            Are you financially prepared to raise a puppy? (Note in reality,
            it’s probably about a tenth of the cost in raising a person)
            <span className="corgi_color">*</span>
          </label>
          <select
            id="TF_Question2"
            name="TF_Question2"
            value={this.state.inputs.financiallyprepared}
            onChange={this.handleChange}
            required
          >
            <option value="Empty"></option>
            <option value="PASS">TRUE</option>
            <option value="FAIL">FALSE</option>
          </select>

          <label htmlFor="TF_Question3">
            Are you a very patient person?
            <span className="corgi_color">*</span>
          </label>
          <select
            id="TF_Question3"
            name="TF_Question3"
            value={this.state.inputs.patience}
            onChange={this.handleChange}
            required
          >
            <option value="Empty"></option>
            <option value="PASS">TRUE</option>
            <option value="FAIL">FALSE</option>
          </select>

          <label htmlFor="TF_Question4">
            Do you have a network of friends and family that are willing to
            raise or look after a dog, too? In case you are busy in your life,
            and you want someone trusted to take care of the dog.
            <span className="corgi_color">*</span>
          </label>
          <select
            id="TF_Question4"
            name="TF_Question4"
            value={this.state.inputs.familynetwork}
            onChange={this.handleChange}
            required
          >
            <option value="Empty"></option>
            <option value="PASS">TRUE</option>
            <option value="FAIL">FALSE</option>
          </select>

          <label htmlFor="TF_Question5">
            Do you enjoy a regular number of physical activities like daily
            walks to hiking activities?<span className="corgi_color">*</span>
          </label>
          <select
            id="TF_Question5"
            name="TF_Question5"
            value={this.state.inputs.physicalactivities}
            onChange={this.handleChange}
            required
          >
            <option value="Empty"></option>
            <option value="PASS">TRUE</option>
            <option value="FAIL">FALSE</option>
          </select>

          <label htmlFor="TF_Question6">
            The next 6 months is like raising a baby. You might wake up at night
            from barking, depending on the puppy. The dog might ask you for a
            potty break late at night, hypothetically speaking. Are you mentally
            prepared for that? (of course, eventually there will no longer be
            any more nighttime duties once the dog is potty trained and house
            trained)
            <span className="corgi_color">*</span>
          </label>
          <select
            id="TF_Question6"
            name="TF_Question6"
            value={this.state.inputs.mentalpreparedness}
            onChange={this.handleChange}
            required
          >
            <option value="Empty"></option>
            <option value="PASS">TRUE</option>
            <option value="FAIL">FALSE</option>
          </select>

          <label htmlFor="TF_Question7">
            Will there be someone available to feed and exercise the puppy
            during the day?<span className="corgi_color">*</span>
          </label>
          <select
            id="TF_Question7"
            name="TF_Question7"
            value={this.state.inputs.caretaker}
            onChange={this.handleChange}
            required
          >
            <option value="Empty"></option>
            <option value="PASS">TRUE</option>
            <option value="FAIL">FALSE</option>
          </select>

          <label htmlFor="TF_Question8">
            Are you or anyone in your family allergic to dogs?
            <span className="corgi_color">*</span>
          </label>
          <select
            id="TF_Question8"
            name="TF_Question8"
            value={this.state.inputs.allergic}
            onChange={this.handleChange}
            required
          >
            <option value="Empty"></option>
            <option value="FAIL">TRUE</option>
            <option value="PASS">FALSE</option>
          </select>

          <label htmlFor="TF_Question9">
            Will you make an effort in finding out if anyone in your household
            is allergic to dogs before meeting the puppies?
            <span className="corgi_color">*</span>
          </label>
          <select
            id="TF_Question9"
            name="TF_Question9"
            value={this.state.inputs.allergictest}
            onChange={this.handleChange}
            required
          >
            <option value="Empty"></option>
            <option value="PASS">TRUE</option>
            <option value="FAIL">FALSE</option>
          </select>

          <label htmlFor="TF_Question10">
            Does your place of residence allows dogs as pets on their premises?
            <span className="corgi_color">*</span>
          </label>
          <select
            id="TF_Question10"
            name="TF_Question10"
            value={this.state.inputs.dogsallowed}
            onChange={this.handleChange}
            required
          >
            <option value="Empty"></option>
            <option value="PASS">TRUE</option>
            <option value="FAIL">FALSE</option>
          </select>

          <label htmlFor="TF_Question11">
            Do you have a fenced yard or suitable pen?
            <span className="corgi_color">*</span>
          </label>
          <select
            id="TF_Question11"
            name="TF_Question11"
            value={this.state.inputs.fencedyard}
            onChange={this.handleChange}
            required
          >
            <option value="Empty"></option>
            <option value="PASS">TRUE</option>
            <option value="FAIL">FALSE</option>
          </select>

          <label htmlFor="TF_Question12">
            Have you ever house trained or potty train a dog before?
            <span className="corgi_color">*</span>
          </label>
          <select
            id="TF_Question12"
            name="TF_Question12"
            value={this.state.inputs.housetraining}
            onChange={this.handleChange}
            required
          >
            <option value="Empty"></option>
            <option value="PASS">TRUE</option>
            <option value="FAIL">FALSE</option>
          </select>

          <label htmlFor="TF_Question13">
            Will you be attending any training classes for your dog (services
            offered virtually on various phone apps, in-person at Petco or
            Petsmart, or private sessions elsewhere)?
            <span className="corgi_color">*</span>
          </label>
          <select
            id="TF_Question13"
            name="TF_Question13"
            value={this.state.inputs.trainingclasses}
            onChange={this.handleChange}
            required
          >
            <option value="Empty"></option>
            <option value="PASS">TRUE</option>
            <option value="FAIL">FALSE</option>
          </select>

          <label htmlFor="TF_Question14">
            Do you intend to breed the dog?
            <span className="corgi_color">*</span>
          </label>
          <select
            id="TF_Question14"
            name="TF_Question14"
            value={this.state.inputs.breeding}
            onChange={this.handleChange}
            required
          >
            <option value="Empty"></option>
            <option value="FAIL">TRUE</option>
            <option value="PASS">FALSE</option>
          </select>

          <label htmlFor="TF_Question15">
            Will my puppy under your care exclusively become a family pet?
            <span className="corgi_color">*</span>
          </label>
          <select
            id="TF_Question15"
            name="TF_Question15"
            value={this.state.inputs.familypet}
            onChange={this.handleChange}
            required
          >
            <option value="Empty"></option>
            <option value="PASS">TRUE</option>
            <option value="FAIL">FALSE</option>
          </select>

          <label htmlFor="TF_Question16">
            Will you provide special training for my puppy (e.g. to become a
            service dog, emotional support dog, etc)?{" "}
            <span className="corgi_color">*</span>
          </label>
          <select
            id="TF_Question16"
            name="TF_Question16"
            value={this.state.inputs.specialtraining}
            onChange={this.handleChange}
            required
          >
            <option value="Empty"></option>
            <option value="YES, will become a support dog">TRUE</option>
            <option value="NO, no special training provided">FALSE</option>
          </select>

          <label htmlFor="TF_Question17">
            Would you be ready to bring the puppy back home on the day of our
            in-person meeting? <span className="corgi_color">*</span>
          </label>
          <select
            id="TF_Question17"
            name="TF_Question17"
            value={this.state.inputs.customerready}
            onChange={this.handleChange}
            required
          >
            <option value="Empty"></option>
            <option value="YES, READY TO GET PUPPY">TRUE</option>
            <option value="NO, WILL NOT BE READY TO BRING HOME">FALSE</option>
          </select>

          <label htmlFor="TF_Question18">
            During the first week of bringing a new puppy home, the puppy can
            undergo stress. For example, individual puppies can experience
            diarrhea, lost of appetite, and among other things. Do you believe
            you are responsible enough to raise a puppy during this period and
            will be mentally fit to confront these challenges?
            <span className="corgi_color">*</span>
          </label>
          <select
            id="TF_Question18"
            name="TF_Question18"
            value={this.state.inputs.puppystress}
            onChange={this.handleChange}
            required
          >
            <option value="Empty"></option>
            <option value="PASS">TRUE</option>
            <option value="FAIL">FALSE</option>
          </select>

          <label htmlFor="TF_Question19">
            Will you agree to communicate with us any concerns you may have of
            the puppy during the first 10 days as soon as possible?{" "}
            <span className="corgi_color">*</span>
          </label>
          <select
            id="TF_Question19"
            name="TF_Question19"
            value={this.state.inputs.concernscommunication}
            onChange={this.handleChange}
            required
          >
            <option value="Empty"></option>
            <option value="PASS">TRUE</option>
            <option value="FAIL">FALSE</option>
          </select>

          <label htmlFor="TF_Question20">
            It is your responsibility to determine whether pet insurance is
            right for you and the puppy. You also have the option to not use pet
            insurance and pay out of pocket. Do you understand that there are
            pet insurances on market? <span className="corgi_color">*</span>
          </label>
          <select
            id="TF_Question20"
            name="TF_Question20"
            value={this.state.inputs.petinsurance}
            onChange={this.handleChange}
            required
          >
            <option value="Empty"></option>
            <option value="PASS">TRUE</option>
            <option value="FAIL">FALSE</option>
          </select>

          <label htmlFor="TF_Question21">
            In the future when your Corgi becomes an adult, if your Corgi
            experiences an emergency, are you financially ready to cover the
            medical expenses for your dog's recovery?{" "}
            <span className="corgi_color">*</span>
          </label>
          <select
            id="TF_Question21"
            name="TF_Question21"
            value={this.state.inputs.medicalemergency}
            onChange={this.handleChange}
            required
          >
            <option value="Empty"></option>
            <option value="PASS">TRUE</option>
            <option value="FAIL">FALSE</option>
          </select>

          <label htmlFor="TF_Question22">
            Dog hair can be an issue for some people. Corgis are moderate
            shedders, so be prepared for dozens to hundreds of strands of dog
            hair on your clothes. Corgis require brushing to get rid of
            excessive hairs, so it is controllable. Corgis don't really need
            haircuts except around their stomach hair areas or bread butt areas
            for hygeinic reasons. Thus, overall, Corgis have moderate to high
            maintenance but low work intensity in grooming since you are not
            getting rid of tangled hairs or styling your Corgi's hair ever. Do
            you like this characteristic of Corgis?{" "}
            <span className="corgi_color">*</span>
          </label>
          <select
            id="TF_Question22"
            name="TF_Question22"
            value={this.state.inputs.shedding}
            onChange={this.handleChange}
            required
          >
            <option value="Empty"></option>
            <option value="PASS">TRUE</option>
            <option value="FAIL">FALSE</option>
          </select>

          <label htmlFor="TF_Question23">
            We offer buyer protections within a 10-day environmental illness
            guarantee as well as a one-year genetic health guarantee. Further
            information will be provided during the in-person meeting. Do you
            understand that we do offer buyer protections?{" "}
            <span className="corgi_color">*</span>
          </label>
          <select
            id="TF_Question23"
            name="TF_Question23"
            value={this.state.inputs.buyerprotections}
            onChange={this.handleChange}
            required
          >
            <option value="Empty"></option>
            <option value="PASS">TRUE</option>
            <option value="FAIL">FALSE</option>
          </select>

          <label htmlFor="TF_Question24">
            Puppyhood is similar to raising a baby. It may take a lot of time
            away from work, or it might push you to juggle more responsibilities
            than you expected. Once you purchase the puppy, you understand you
            are expected to persevere through any challenges. You understand you
            are now solely responsible for the overall safety and wellbeing of
            the puppy under your ownership. Do you understand that our buyer
            protections do not include a return related to a loss of interest in
            continuing to own the puppy? <span className="corgi_color">*</span>
          </label>
          <select
            id="TF_Question24"
            name="TF_Question24"
            value={this.state.inputs.norefundpolicy}
            onChange={this.handleChange}
            required
          >
            <option value="Empty"></option>
            <option value="PASS">TRUE</option>
            <option value="FAIL">FALSE</option>
          </select>

          <label htmlFor="TF_Question25">
            If you have other pets at home, will you make an effort to train the
            new puppy and your current pets to get along? You understand it
            takes time for everyone to get used to one another to become
            companions. Do you understand that conflicts between your current
            pets and the new puppy do not qualify for a return of the purchase
            price of the puppy? <span className="corgi_color">*</span>
          </label>
          <select
            id="TF_Question25"
            name="TF_Question25"
            value={this.state.inputs.petsmeetnewpuppy}
            onChange={this.handleChange}
            required
          >
            <option value="Empty"></option>
            <option value="PASS">TRUE</option>
            <option value="FAIL">FALSE</option>
            <option value="NOT APPLICABLE">I don't have other pets. </option>
          </select>

          <label htmlFor="TF_Question26">
            Once you have full ownership of the puppy, you have the right to
            gift the puppy to family, friends, coworkers, or anyone you know.
            However, our sales agreement will not carry over to new owners. Do
            you understand this aspect of full ownership?{" "}
            <span className="corgi_color">*</span>
          </label>
          <select
            id="TF_Question26"
            name="TF_Question26"
            value={this.state.inputs.ownership}
            onChange={this.handleChange}
            required
          >
            <option value="Empty"></option>
            <option value="PASS">TRUE</option>
            <option value="FAIL">FALSE</option>
          </select>

          <label htmlFor="TF_Question27">
            Do you understand that should you not want to keep the puppy any
            longer, you have to option to return the puppy back to us under our
            full ownership and care? Though you will not get any refunds for the
            purchase price of the puppy nor will you get reimbursements for any
            related expenses to the puppy.{" "}
            <span className="corgi_color">*</span>
          </label>
          <select
            id="TF_Question27"
            name="TF_Question27"
            value={this.state.inputs.returnpolicy}
            onChange={this.handleChange}
            required
          >
            <option value="Empty"></option>
            <option value="PASS">TRUE</option>
            <option value="FAIL">FALSE</option>
          </select>

          <label htmlFor="TF_Question28">
            The total cost in the first year of bringing a puppy home can total
            four figures. This includes the purchase price of the puppy, vet and
            vaccination expenses, pet insurance, neuter/spay surgical expenses,
            food, toys, training and education, grooming and shampoo,
            medications, along other things. Do you understand the first year
            financial responsibilities in introducing a puppy to a new home and
            will you be able to manage that?{" "}
            <span className="corgi_color">*</span>
          </label>
          <select
            id="TF_Question28"
            name="TF_Question28"
            value={this.state.inputs.fourfigures}
            onChange={this.handleChange}
            required
          >
            <option value="Empty"></option>
            <option value="PASS">TRUE</option>
            <option value="FAIL">FALSE</option>
          </select>

          <label htmlFor="TF_Question29">
            We typically do not accept deposits for our puppies because we want
            to make sure the puppy you choose has the right temperament and
            personality that matches more closely to your preferences. This is
            why in-person meetings are so important before we finalize the
            purchase. Do you understand the necessity of this process compared
            to the self proclaimed "puppy shippers" or "puppy nanny" that
            guarantee the perfect puppy to you delivered right at your home?{" "}
            <span className="corgi_color">*</span>
          </label>
          <select
            id="TF_Question29"
            name="TF_Question29"
            value={this.state.inputs.nodeposits}
            onChange={this.handleChange}
            required
          >
            <option value="Empty"></option>
            <option value="PASS">TRUE</option>
            <option value="FAIL">FALSE</option>
          </select>

          <label htmlFor="line-of-work">
            Can you describe your line of work or the field or industry you are
            in? <span className="corgi_color">*</span>
          </label>
          <textarea
            id="line-of-work"
            className="message"
            name="line-of-work"
            placeholder="Describe your line of work (required)"
            value={this.state.inputs.lineofwork}
            onChange={this.handleChange}
            required
          ></textarea>

          <label htmlFor="family-worklifestyle">
            How is your family’s work lifestyle like? Do you have enough free
            time to raise a dog?<span className="corgi_color">*</span>
          </label>
          <textarea
            id="family-worklifestyle"
            className="message"
            name="family-worklifestyle"
            placeholder="Describe family's work lifestyle (required)"
            value={this.state.inputs.worklifestyle}
            onChange={this.handleChange}
            required
          ></textarea>

          <label htmlFor="primary-caretaker">
            Who will be primarily responsible in raising the dog? And can they
            make the time?<span className="corgi_color">*</span>
          </label>
          <textarea
            id="primary-caretaker"
            className="message"
            name="primary-caretaker"
            placeholder="Describe who is the primary caretaker (required)"
            value={this.state.inputs.primarycaretaker}
            onChange={this.handleChange}
            required
          ></textarea>

          <label htmlFor="place-of-residence">
            Do you currently live in an apartment or home with a backyard? Do
            you have a plan of action for potty training?
            <span className="corgi_color">*</span>
          </label>
          <textarea
            id="place-of-residence"
            className="message"
            name="place-of-residence"
            placeholder="Describe living situation and training (required)"
            value={this.state.inputs.placeofresidence}
            onChange={this.handleChange}
            required
          ></textarea>

          <label htmlFor="puppy-home">
            Where will the puppy be kept during the day and night? How many
            hours a day will the puppy be kept outside? How will the puppy be
            confined outside?<span className="corgi_color">*</span>
          </label>
          <textarea
            id="puppy-home"
            className="message"
            name="puppy-home"
            placeholder="Describe area of confinement (required)"
            value={this.state.inputs.puppyhome}
            onChange={this.handleChange}
            required
          ></textarea>

          <label htmlFor="alone-time">
            How many hours, on average, will the puppy be left alone per day?
            How frequent will this be?<span className="corgi_color">*</span>
          </label>
          <input
            type="text"
            className="alone-time"
            id="alone-time"
            name="alone-time"
            placeholder="Detail how long the puppy will be alone for per day and how often (required)"
            value={this.state.inputs.alonetime}
            onChange={this.handleChange}
            required
          />

          <label htmlFor="family-children">
            Do you have any children? If so, do you feel they are old enough or
            responsible enough to look after a dog?
            <span className="corgi_color">*</span>
          </label>
          <input
            type="text"
            className="family-children"
            id="family-children"
            name="family-children"
            placeholder="Detail what you think your children will do with a new puppy in the family (required)"
            value={this.state.inputs.familychildren}
            onChange={this.handleChange}
            required
          />

          <label htmlFor="teen-troubles">
            An unfortunate reality is that teenage children tend to forget about
            their pets. The idea that the pet is a member of the family tends to
            get lost. Dogs need constant socialization, love, play time,
            affection, and many other things to live a long, fulfilling life.
            What guarantees can you provide for me that our puppy here will go
            to a good family and home? How will that look like? Life gets busy
            fast, so how can you circumvent that?
            <span className="corgi_color">*</span>
          </label>
          <textarea
            id="teen-troubles"
            className="message"
            name="teen-troubles"
            placeholder="Question is specific to families with teens, but could actually apply to all families, too. (required)"
            value={this.state.inputs.teentroubles}
            onChange={this.handleChange}
            required
          ></textarea>

          <label htmlFor="puppy-gender">
            Are you interested in a male or female puppy?
            <span className="corgi_color">*</span>
          </label>
          <select
            id="puppy-gender"
            name="puppy-gender"
            value={this.state.inputs.puppygender}
            onChange={this.handleChange}
            required
          >
            <option value="Empty"></option>
            <option value="Wants a MALE PUPPY">MALE</option>
            <option value="Wants a FEMALE PUPPY">FEMALE</option>
            <option value="NO GENDER PREFERENCE">NO GENDER PREFERENCE</option>
          </select>

          <label htmlFor="activity-level">
            What do you expect the activity level of this breed to be?
            <span className="corgi_color">*</span>
          </label>
          <select
            id="activity-level"
            name="activity-level"
            value={this.state.inputs.activitylevel}
            onChange={this.handleChange}
            required
          >
            <option value="Empty"></option>
            <option value="VERY HIGH">VERY HIGH</option>
            <option value="HIGH">HIGH</option>
            <option value="MODERATE">MODERATE</option>
            <option value="LAZY">LAZY</option>
          </select>

          <label htmlFor="championship-shows">
            Will you take our puppy to compete in competitions, championships,
            etc? Do you have any dogs with performance titles? Note: our pups
            are bred to become family pets. Our breeding dogs have not competed
            in any competitions.
          </label>
          <textarea
            id="championship-shows"
            className="message"
            name="championship-shows"
            placeholder="Describe your participation in championships, if any?"
            value={this.state.inputs.championshipshows}
            onChange={this.handleChange}
          ></textarea>

          <label htmlFor="old-pets">
            How long did your previous pet live? What were the circumstances of
            its death?
          </label>
          <textarea
            id="old-pets"
            className="message"
            name="old-pets"
            placeholder="Describe your previous pets, if any?"
            value={this.state.inputs.oldpets}
            onChange={this.handleChange}
          ></textarea>

          <label htmlFor="puppy-search">
            Do you have prior experience in finding a dog online? Were you
            scammed? Do you now understand the importance in meeting a dog in
            person?
          </label>
          <textarea
            id="puppy-search"
            className="message"
            name="puppy-search"
            placeholder="Describe your experience in finding a puppy online, if any?"
            value={this.state.inputs.puppysearch}
            onChange={this.handleChange}
          ></textarea>

          <label htmlFor="puppy-return">
            Have you ever returned a pet to the breeder? If so, what were the
            circumstances?
          </label>
          <textarea
            id="puppy-return"
            className="message"
            name="puppy-return"
            placeholder="Describe your experience in returning a pet to the breeder, if any?"
            value={this.state.inputs.puppyreturn}
            onChange={this.handleChange}
          ></textarea>

          <label htmlFor="puppy-giveaway">
            Have you ever given a pet away? Or, have you ever taken a pet to a
            pound or shelter? If so, what were the circumstances?
          </label>
          <textarea
            id="puppy-giveaway"
            className="message"
            name="puppy-giveaway"
            placeholder="Describe your experience giving a pet away, if any?"
            value={this.state.inputs.puppygiveaway}
            onChange={this.handleChange}
          ></textarea>

          <label htmlFor="vet-info">
            Please provide the name, address, phone number, and website of your
            veterinarian.<span className="corgi_color">*</span>
          </label>
          <textarea
            id="vet-info"
            className="message"
            name="vet-info"
            placeholder="Provide the name, address, website, and phone number of your veterinarian (required)"
            value={this.state.inputs.vetinfo}
            onChange={this.handleChange}
            required
          ></textarea>

          <label htmlFor="TF_Last_Question">
            To recap, dogs need physical activity and social interaction to live
            life to the fullest. Do you strongly believe your family is a right
            fit for our Corgis?
            <span className="corgi_color">*</span>
          </label>
          <select
            id="TF_Last_Question"
            name="TF_Last_Question"
            value={this.state.inputs.recap}
            onChange={this.handleChange}
            required
          >
            <option value="Empty"></option>
            <option value="PASS">TRUE</option>
            <option value="FAIL">FALSE</option>
          </select>

          <label htmlFor="Wait_List_Question">
            Do you prefer to be placed on our waitlist for our future litter?
            You understand that it is first come first serve and that we will
            reach out to you then regarding available puppies.{" "}
            <span className="corgi_color">*</span>
          </label>
          <select
            id="Wait_List_Question"
            name="Wait_List_Question"
            value={this.state.inputs.waitlist}
            onChange={this.handleChange}
            required
          >
            <option value="Empty"></option>
            <option value="PLACE THEM ON WAITLIST">
              I PREFER TO BE ON THE WAITLIST FOR THE NEXT LITTER
            </option>
            <option value="READY TO ADOPT ANYTIME">
              I AM READY TO GET A PUPPY NOW OR ANYTIME! I CAN BE ON THE WAITLIST
              IF A LITTER IS NOT AVAILABLE.{" "}
            </option>
            <option value="PRIORITY MUST ADOPT NOW">
              I AM READY TO GET A PUPPY NOW OR ANYTIME. I DO NOT WANT TO BE ON
              THE WAITLIST FOR A FUTURE LITTER.{" "}
            </option>
          </select>

          <label htmlFor="available-meeting-time">
            When will you be available for an in-person meeting (Include date
            and time)? Note: This appointment time is not scheduled until we
            contact you for confirmation. <span className="corgi_color">*</span>
          </label>
          <input
            type="text"
            id="available-meeting-time"
            name="available-meeting-time"
            placeholder="appointment schedule time (required)"
            value={this.state.inputs.meeting}
            onChange={this.handleChange}
            required
          />

          <p className="privacy-policy-label">
            PRIVACY<span className="corgi_color">*</span>
          </p>
          <label
            htmlFor="privacypolicy"
            className="contact-form-privacy-policy-container"
          >
            <input
              type="checkbox"
              id="privacy"
              name="privacypolicy"
              value="user-agreed-to-privacy-policy"
              onChange={this.handleChange}
              required
            />{" "}
            I have read and accept the{" "}
            <a
              className="contact-form-privacy-policy"
              href="https://bayareacorgis.com/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            <span className="corgi_color">*</span>.
          </label>

          <div className="g-recaptcha">
            <ReCAPTCHA
              sitekey="6LfmvSMgAAAAACJjpyuop9Yl3Fe6zQ374fYFY_6L"
              ref={(e) => (recaptchaInstance = e)}
              render="explicit"
              onloadCallback={this.recaptchaLoaded}
              verifyCallback={this.handleReCAPTCHA}
            />
          </div>

          {/* <h3>
          ***At this time, we are experiencing technical difficulties with the
          submission button for our contact form (the button is temporarily
          removed), please screenshot your answers to the questions. Please text
          us at 415-996-5669 your name, email, phone number, availability for an
          in-person meeting and the screenshots of your completed screening
          questions. Thank you! ***{" "}
        </h3> */}

          <h3>
            ***REMINDER: As a backup, please screenshot your answers to the
            questions BEFORE YOU CLICK SUBMIT. Please text us that you just made
            a submission on our contact page; that way we can verify that
            everything went through. If not, we will let you know if we need
            your screenshots of your answers for review. ***{" "}
          </h3>

          <button
            className="button"
            id="form-submit-button"
            type="submit"
            name="Submit"
            onClick={this.submitScreeningQuestionForm}
          >
            SUBMIT FORM
          </button>
        </form>
      </section>
    );
  }
}

ReactDOM.render(<ScreeningQuestionForm />, document.getElementById("root"));
export default ScreeningQuestionForm;
