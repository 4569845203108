import React from "react";
//Need this to switch pages
//V6 React Router Dom Updates
import { Routes, Route } from "react-router-dom";
//Route Based code splitting using loadable
import loadable from "@loadable/component";

//Pages
import ContactPage from "../pages/Contact.js";
const HomePage = loadable(() => import("../pages/Home.js"));
const AvailablePuppiesPage = loadable(() =>
  import("../pages/AvailablePuppies.js")
);
const StudServicePage = loadable(() => import("../pages/StudService.js"));
const AboutUsPage = loadable(() => import("../pages/AboutUs.js"));
const PastPuppiesPage = loadable(() => import("../pages/PastPuppies.js"));
const OurCustomersPage = loadable(() => import("../pages/OurCustomers.js"));
const BlogPage = loadable(() => import("../pages/BlogHome.js"));
const ResourceDocumentsPage = loadable(() =>
  import("../pages/ResourceDocuments.js")
);
const SalesAgreementFormPage = loadable(() =>
  import("../pages/SalesAgreementForm.jsx")
);
// const Admin = loadable(() => import("../pages/Admin.js"));
// Blog Pages
const WhatToKnowWhenBuyingACorgiPuppy = loadable(() =>
  import("../pages/blog_pages/what_to_know_when_buying_a_corgi_puppy.js")
);
const EightBestPlacesSF = loadable(() =>
  import(
    "../pages/blog_pages/8_best_places_to_take_your_corgi_to_in_san_francisco.js"
  )
);
const PatriotPawsServiceDogs = loadable(() =>
  import("../pages/blog_pages/patriot_paws_service_dogs.js")
);
//404 Page
const NotFoundPage = loadable(() => import("../pages/NotFoundPage.js"));
const PrivacyPolicy = loadable(() => import("../pages/PrivacyPolicy.js"));

const RoutePaths = ({ history }) => (
  <Routes history={history}>
    {/* A <Routes> looks through its children <Route> and renders the first one that matches the current URL. */}
    <Route path="/" element={<HomePage />} />
    <Route path="/available-puppies" element={<AvailablePuppiesPage />} />
    <Route path="/stud-service" element={<StudServicePage />} />
    <Route path="/about-us" element={<AboutUsPage />} />
    <Route path="/past-puppies" element={<PastPuppiesPage />} />
    <Route path="/our-customers" element={<OurCustomersPage />} />
    <Route path="/contact" element={<ContactPage />} />
    <Route path="/blog" element={<BlogPage />} />
    <Route path="/resource-documents" element={<ResourceDocumentsPage />} />
    <Route path="/sales-agreement-form" element={<SalesAgreementFormPage />} />
    {/* <Route path="/admin" element={<Admin/>}/> */}
    <Route path="/privacy-policy" element={<PrivacyPolicy />} />

    {/* Blog Paths */}
    <Route
      path="/blog/what_to_know_when_buying_a_corgi_puppy"
      element={<WhatToKnowWhenBuyingACorgiPuppy />}
    />
    <Route
      path="/blog/8_best_places_to_take_your_corgi_to_in_san_francisco"
      element={<EightBestPlacesSF />}
    />
    <Route
      path="/blog/charities/patriot_paws_service_dogs"
      element={<PatriotPawsServiceDogs />}
    />

    {/* 404 page */}
    <Route path="/404" element={<NotFoundPage />} />
    <Route path="*" element={<NotFoundPage />} />
  </Routes>
);
export default RoutePaths;
